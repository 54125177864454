<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapActions,mapState,mapMutations  } from "vuex";

export default {
	async mounted() {
		if( this.rentalPoolFilter.length !=0 ) return false
			let rentalPool= await this.fetchGetRentalPoolFilter();
      		this.setRentalPoolFilter(rentalPool)
    },
	computed:{
		...mapState("fivesClubProfit", ['rentalPoolFilter']),
		currentRouteName() {
			return this.$route.name
		}
	},
	methods: {
		...mapMutations('fivesClubProfit', ['setRentalPoolFilter']),
		...mapActions('fivesClubProfit', ["fetchGetRentalPoolFilter"]),
		activeClass(...names) {
			for (let name of names){
				return name == this.$route.name ? 'active' : ''
			}
		}
	},
};
</script>
